.nothing-to-do {
    justify-content: center;
    align-items: center;
    text-align: center;
}

.item {
    border: 1px solid var(--bs-gray-400);
}

.item-text {
    overflow: auto;
    overflow-wrap: break-word;
}
