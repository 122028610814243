.banner {
    align-items: center;
    background-color: var(--bs-orange);
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;
}

.banner > .description,
.banner > .supportToken {
    color: var(--bs-white);
    font-size: 1.2375rem; /* fs-3 */
    font-weight: bolder;
}

.banner > button {
    font-weight: bolder;
    margin-left: auto;
    margin-right: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

@media (max-width: 576px) {
    .banner > .description,
    .banner > .supportToken {
        font-size: 1rem;
    }

    .banner > :first-child {
        flex-basis: 100%;
        text-align: center;
    }

    .banner > button {
        width: 100%;
        margin: 0 !important;
    }
}
