/*
 * Fix the bug where align="end" doesn't really align menu to the right.
 * This css leverages on the css class generated with align="end" property,
 * so it's stil required.
 */
.nav-item .dropdown-menu-end {
    right: 0 !important;
}

.banner {
    font-size: 1.5em;
    color: white;
    text-align: center;
}

.banner-test {
    background-color: blue;
}

.banner-staging {
    background-color: #ff8800;
}

.banner-prod {
    background-color: red;
}

.banner-localhost {
    background-color: green;
}

.banner-debug {
    background-color: rgba(255, 251, 0, 0.536);
    color: #000000;
}

.sidebar-toggle.has-badge {
    position: relative;
}

.sidebar-toggle.has-badge::after {
    background-color: var(--bs-orange);
    border-radius: 50%;
    content: '';
    height: 14px;
    position: absolute;
    right: -6px;
    top: -5px;
    width: 14px;
}
