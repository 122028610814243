.main {
    margin-left: 50px;
}

.main.small {
    margin-left: 0;
}

.main.pinned {
    margin-left: 260px;
}
