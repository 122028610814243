.main-menu {
    background-color: rgb(33, 38, 53);
    direction: ltr;
    min-width: 260px;
    position: fixed;
    overflow: hidden;
    transition: min-width 0.35s ease-in-out, left 0.35s ease-in-out, width 0.35s ease-in-out, right 0.35s ease-in-out;
    z-index: 999;
    width: 250px;
}

.main-menu.collapsed {
    width: 50px;
    min-width: 50px;
}

.main-menu.collapsed.small {
    width: 0px;
    min-width: 0px;
}

.menu-brand {
    padding: 0;
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
    color: white;
}

.menu-brand-inst {
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
    text-align: center;
    color: white;
    font-weight: bold;
    overflow-wrap: 'break-word';
    transition: opacity 0.35s ease-in-out;
    opacity: 1;
}

.menu-brand-inst.collapsed {
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
    /* color: transparent; */
    transition: opacity 0.35s ease-in-out;
    opacity: 0;
}

.menu-brand.collapsed {
    color: transparent;
    transition: color 0.35s ease-in-out;
}

.navbar-vertical-label.collapsed {
    color: transparent;
    transition: color 0.35s ease-in-out;
}

.navbar-vertical-label {
    color: #6c757d;
    transition: color 0.35s ease-in-out;
}

.menu-static {
    height: 100%;
    padding-bottom: calc(100% - 20rem);
}

.main-menu-content {
    height: unset !important;
}

.menu-border {
    border-right: 1px solid #fff;
}

.navigation {
    --scrollbar-bg: var(--bs-gray-700);
    --scrollbar-handle: var(--bs-gray-600);

    margin: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100vh;

    /* style side bar scrollbar in Firefox */
    scrollbar-color: var(--scrollbar-bg) transparent;
    scrollbar-width: auto;
}

/* Style side bar scrollbar in Chrome / Edge (webkit) */
/* width */
.navigation::-webkit-scrollbar {
    width: 12px;
}

/* Handle */
.navigation::-webkit-scrollbar-thumb {
    background: var(--scrollbar-bg);
}

/* Handle on hover */
.navigation::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-handle);
}

/* Hide scrollbar when sidebar is collapsed (in Chrome/Edge) */
.main-menu[aria-expanded='false'] .navigation::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar when sidebar is collapsed (in Firefox) */
.main-menu[aria-expanded='false'] .navigation {
    scrollbar-width: none;
}

.menu-li {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.menu-ul {
    padding: 0px;
}

a.sidebar-links {
    color: rgba(233, 236, 239, 1);
    cursor: pointer;
    display: block;
    padding: 0.625rem 0.8rem;
    position: relative;
    text-decoration: none;
    transition: color 75ms ease-in-out;
}
.sidebar-items {
    font-weight: 400;
    color: #000; /* Change this to match your design */
    text-decoration: none; /* Removes the default underline from links */
}

/* Hover state of the link */
.sidebar-items:hover {
    color: #ff0; /* Change this to match your design */
    background-color: rgb(49, 56, 78);
}

/* Active link */
.sidebar-items.active {
    color: #ff0; /* Change this to match your design */
    background-color: rgb(65, 74, 104);
    font-weight: bold;
}

a.sidebar-links .has-badge {
    position: relative;
}

a.sidebar-links .has-badge::after {
    background-color: var(--bs-orange);
    border-radius: 50%;
    content: '';
    height: 10px;
    position: absolute;
    right: -4px;
    top: -4px;
    width: 10px;
}

a.menu-brand {
    color: white;
    font-weight: 500;
    font-size: large;
    cursor: pointer;
    display: block;
    padding: 1.3rem 0.5rem;
    text-align: center;
    position: relative;
    text-decoration: none;
    transition: color 75ms ease-in-out;
}

.row {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.navigation > li.version {
    padding: 0.8rem;
    text-overflow: clip;
}

.navigation > li.version > code {
    color: var(--bs-gray-600);
    font-size: 0.9rem;
    transition: 0.5s color ease-in;
}

.main-menu[aria-expanded='true'] .navigation > li.version > code {
    color: var(--bs-gray-600);
}

.main-menu[aria-expanded='false'] .navigation > li.version > code {
    color: transparent;
}
