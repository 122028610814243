body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.app-background {
    background-image: url('images/toys.jpg');
    background-size: cover;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.new-look-bar-close {
    float: right;
    color: white;
    margin-right: 20px;
    cursor: pointer;
}

.new-look-bar {
    text-align: center;
    background: #333333;
    color: #f0f0f0;
    cursor: pointer;
    padding: 4px;
}

.new-look-bar a {
    color: #ffffff;
    text-decoration: underline;
}

/* disable that dropdown menus open above their dropdown button in certain themes */
.dropdown-menu {
    inset: inherit !important;
}

/* very annoying if b is not bold */
b {
    font-weight: 700 !important;
}

/* float-end causes a flow problem */
.close-button {
    position: absolute;
    right: 10px;
}

/* Alerts look bad in the current theme, unusable as error messages because of too little padding */
.alert-danger {
    padding: 8px;
}

.form-label.required {
    font-weight: 500;
}

.form-label.required::after {
    color: var(--bs-red);
    content: '*';
    padding-left: 0.15rem;
}

/** Sidebar **/
#sidebar .simplebar-content {
    background-color: #293042;
}

.timeline-closed-hours {
    background: repeating-linear-gradient(-45deg, #f3f3f3, #f3f3f3 10px, #e5e5e5 10px, #e5e5e5 20px);
}
